.app-date-picker {
  .calendar-wrapper {
    // TEMPORARY, fix later
    max-width: 500px !important;
  }
  .mat-calendar-body-cell,
  button.mat-calendar-body-cell {
    padding: 0;
  }
  .mat-calendar-body-cell-content {
    border-radius: 0 !important;
    border: 2px var(--theme-primary) solid !important;
    font-size: large !important;
    color: var(--black) !important;
  }
  .mat-calendar-body-selected {
    background-color: var(--theme-primary) !important;
    box-shadow: none !important;
    color: var(--white) !important;
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content {
      background: var(--grey200) !important;
    }
  }

  .is-highlighted {
    .mat-calendar-body-cell-content {
      background: var(--greenLight);
    }

    // highlight disabled (might be weekends) cells as well
    // &.mat-calendar-body-disabled .mat-calendar-body-cell-content {
    //   background: $color !important;
    //   opacity: 0.5;
    // }
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content.mat-calendar-body-today {
      border-color: var(--primary-outline) !important;
    }
  }

  .mat-calendar-body-label {
    color: var(--black) !important;
  }

  .mat-calendar-body-label {
    opacity: 0 !important;
  }

  .mat-calendar-body-label[colspan="7"] {
    display: none !important;
  }
  .date-picker-header {
    display: flex;
    align-items: center;
    padding: 0.5em;
  }

  .date-picker-header-label {
    flex: 1;
    height: 1.7em;
    font-weight: 500;
    text-align: center;
  }
  .double-arrow {
    margin: -35%;
  }
}
