%colors {
  // primary colors
  --white: #fff;
  --black: #000;
  // secondary colors

  --grey100: #dfe4e8;
  --grey200: #c2cacf;
  --grey300: #96a3a8;
  --grey400: #6a767d;
  --grey500: #3c484d;

  --blue300: #0082d6;
  --blue500: #00437a;

  --secondary-white: #eff1f3;
  --secondary-light: #d1dae24d;

  // signal colors
  --greenLight: #9bd69b;
  --green100: #029640;
  --orange100: #ffd100;
  --red100: #e4002c;
  --mint100: #00e6e6;
  --purple100: #5b08a4;

  // fonts

  --font-family-base: sans-serif;
  --font-weight-lighter: lighter;
  --font-weight-light: 200;
  --font-weight-normal: 300;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-bolder: bolder;

  /* Borders and Radius */
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.15);

  /* Typography */
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;

  /* Primary Button */
  --bs-btn-active-color: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-bg: #001e50;

  /* RGB Theme Colors */
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
}

@include not-feature-app() {
  :root {
    @extend %colors;
  }
}
@include only-feature-app() {
  app-vbo-root {
    @extend %colors;
  }
}

@media (min-width: 1200px) {
  app-vbo-root .container-xl,
  app-vbo-root .container-lg,
  app-vbo-root .container-md,
  app-vbo-root .container-sm,
  app-vbo-root .container {
    max-width: 1140px;
  }
}

// Enables negative margins with bootstrap css-classes
$enable-negative-margins: true;
