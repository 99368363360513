body {
  font-family: var(--font-family-base, "sans-serif");
}

.app {
  h1,
  .h1 {
    font-size: 24px;
    font-weight: var(--font-weight-light);
  }

  h2,
  .h2 {
    font-size: 18px;
    font-weight: var(--font-weight-light);
  }

  h3,
  .h3 {
    font-size: 16px;
    font-weight: var(--font-weight-light);
  }

  a {
    font-weight: var(--font-weight-normal);
    color: var(--text-link);
    cursor: pointer;

    &:hover {
      color: var(--text-link-hover);
    }
  }

  p {
    font-size: 16px;
    font-weight: var(--font-weight-light);
  }

  b {
    font-weight: var(--font-weight-semibold);
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  .font-weight-light {
    font-weight: var(--font-weight-light) !important;
  }

  .text-with-icon {
    font-size: 16px;
    color: var(--theme-primary);
    cursor: pointer;
  }

  .mat-option {
    font-family: var(--font-family-base);
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .fs-20 {
    font-size: 20px;
  }

  .fs-22 {
    font-size: 22px;
  }

  .fs-30 {
    font-size: 30px;
  }
}
