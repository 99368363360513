@use "sass:map";
@use "sass:list";

$fonts: (
  "VW text": (
    (
      src: "/assets/fonts/vw-text/light/VWTextWeb-Light",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/vw-text/regular/VWTextWeb-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/vw-text/regular/VWTextWeb-RegularItelic",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
      style: italic,
    ),
    (
      src: "/assets/fonts/vw-text/bold/VWTextWeb-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/vw-text/bold/VWTextWeb-BoldItalic",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
      style: italic,
    ),
    (
      src: "/assets/fonts/vw/extrabold/VWHead-ExtraBold",
      weight: 700,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
  "Audi Type Screen": (
    (
      src: "/assets/fonts/audi/AudiTypeScreen-Light",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/audi/AudiTypeScreen-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/audi/AudiTypeScreen-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
  "SKODA Next": (
    (
      src: "/assets/fonts/skoda/SKODANext-Light",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/skoda/SKODANext-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/skoda/SKODANext-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
  "SeatBcn-Web": (
    (
      src: "/assets/fonts/seat/SEatBcn-Web-Light",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/seat/SEatBcn-Web-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/seat/SEatBcn-Web-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
  "Cupra": (
    (
      src: "/assets/fonts/cupra/Cupra-Light",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/cupra/Cupra-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/cupra/Cupra-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
  "PorscheNextTT": (
    (
      src: "/assets/fonts/porsche/PorscheNextTT-Thin",
      weight: 200,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/porsche/PorscheNextTT-ThinBold",
      weight: 250,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/porsche/PorscheNextTT-Regular",
      weight: 300,
      formats: (
        "woff2",
        "woff",
      ),
    ),
    (
      src: "/assets/fonts/porsche/PorscheNextTT-Bold",
      weight: 600,
      formats: (
        "woff2",
        "woff",
      ),
    ),
  ),
);

$defaultFace: (
  style: normal,
  display: block,
);

@each $fontFamily, $faces in $fonts {
  @each $face in $faces {
    $face: map.merge($defaultFace, $face);
    $src: ();
    @each $format in map.get($face, "formats") {
      $url: null;
      @if variable-exists("__FEATURE_APP_BASE_URL__") and
        $__FEATURE_APP_BASE_URL__
      {
        $url: "#{#{$__FEATURE_APP_BASE_URL__}#{map.get($face, 'src')}#{'.'}#{$format}}";
      } @else {
        $url: "#{#{map.get($face, 'src')}#{'.'}#{$format}}";
      }
      $src: list.append(
        $src,
        list.append(#{'url("#{$url}")'}, format($format), $separator: space),
        $separator: comma
      );
    }
    @font-face {
      font-family: $fontFamily;
      src: $src;
      font-weight: map.get($face, "weight");
      font-style: map.get($face, "style");
      font-display: map.get($face, "display");
    }
  }
}
