app-cookie-information {
  tbody {
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 1px;
    border-right-width: 1px;
    margin-top: 1px;
    margin-bottom: 1px;
    border-color: transparent;
  }
  tbody:target {
    border-color: rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-light);
    margin-top: 0;
    margin-bottom: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
}
