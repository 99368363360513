.app-registration-number-box {
  input {
    height: 72px !important;
    text-align: center !important;
    border-radius: 0px !important;
    font-size: 36px !important;
    border-bottom: 0px !important;
    text-transform: uppercase !important;
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder,
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-align: center !important;
      font-size: 36px !important;
      text-transform: uppercase !important;
      opacity: 1; /* Firefox */
      line-height: normal !important;
      vertical-align: middle;
    }
    &::placeholder &::-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::-webkit-input-placeholder {
      text-align: center !important;
      font-size: 36px !important;
      text-transform: uppercase !important;
      line-height: 36px !important;
      vertical-align: middle;
    }
  }

  .form-control {
    z-index: 1 !important;
    font-weight: var(--font-weight-light);
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    height: 48px;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      font-style: italic;
      font-size: 16px;
      font-size: italic;
      text-transform: capitalize;
      font-weight: var(--font-weight-normal);
      color: var(--grey300);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding-right: 3px;
    }
  }

  input:focus::placeholder {
    color: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .search-clear {
    z-index: 100;
    margin-left: -40px;
    position: relative;
  }
}
