@use "sass:color";

.app-slider {
  position: sticky;
  display: flex;
  flex-direction: column;
  padding: 0 0.25rem;
  .mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: var(--white);
    --mdc-slider-focus-handle-color: var(--white);
    --mdc-slider-hover-handle-color: var(--white);
    --mdc-slider-active-track-color: var(--theme-primary);
    --mdc-slider-inactive-track-color: var(--theme-primary);
    --mdc-slider-with-tick-marks-active-container-color: var(--white);
    --mdc-slider-with-tick-marks-inactive-container-color: var(--theme-primary);
    --mat-mdc-slider-ripple-color: var(--theme-primary);
    @include with-theme-var("theme-primary") using ($primary) {
      --mat-mdc-slider-hover-ripple-color: #{color.change(
          $primary,
          $alpha: 0.05
        )};
      --mat-mdc-slider-active-ripple-color: #{color.change(
          $primary,
          $alpha: 0.2
        )};
    }
    --mat-mdc-slider-focus-ripple-color: transparent;
  }

  .mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple {
    background-color: var(--mat-mdc-slider-active-ripple-color, transparent);
  }

  /* Only activate focus ripple when the focus-visible selector is used */
  &:has(:focus-visible) {
    .mat-mdc-slider.mat-primary {
      @include with-theme-var("theme-primary") using ($primary) {
        --mat-mdc-slider-focus-ripple-color: #{color.change(
            $primary,
            $alpha: 0.2
          )};
      }
    }
    .mat-mdc-slider
      .mat-ripple
      .mat-mdc-slider-hover-ripple.mat-mdc-slider-focus-ripple {
      background-color: var(--mat-mdc-slider-focus-ripple-color, transparent);
    }
  }

  --mdc-slider-handle-width: 24px;
  --mdc-slider-handle-height: 24px;

  --mdc-slider-active-track-height: 7px;
  --mdc-slider-inactive-track-height: 7px;

  /* border radius: 10px; */
  --mdc-slider-active-track-shape: 10px;
  --mdc-slider-inactive-track-shape: 10px;
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    border: 0 !important;
    box-shadow: 0px 2px 9px rgb(0 0 0 / 30%) !important;
    background-color: var(--white) !important;
  }

  .mat-mdc-slider .mdc-slider__thumb-knob {
    box-shadow: 0px 2px 9px rgb(0 0 0 / 30%) !important;
    z-index: 9;
  }
}
