.app-application-message {
  .application-message {
    position: relative;

    ::ng-deep .mat-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: none;
    }
  }
}
