@mixin only-feature-app() {
  @if variable-exists(__FEATURE_APP_BASE_URL__) and $__FEATURE_APP_BASE_URL__ {
    @content;
  }
}
@mixin not-feature-app() {
  @if not
    variable-exists(__FEATURE_APP_BASE_URL__) or not
    $__FEATURE_APP_BASE_URL__
  {
    @content;
  }
}

// global styles
@include only-feature-app {
  @import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
  app-vbo-root {
    @import "scss/global/_index.scss";
    @import "scss/themes.scss";
    @import "bootstrap/scss/bootstrap";
  }
  @import "node_modules/@angular/material/_index.scss";

  /* Time of writing: 2024-04-09
  the @scoped rule will completely solve our styles leaking out to the host site.
  Sadly adoption is not yet up to speed (~70% at the time of writing)
  Safari has implemented it last month, and a supported version is rolling out to users¨
  but it's not widely deployed yet.
  
  FireFox has decided they're positive to this, and will implement it hopefully sometime
  in mid-2024.
  
  https://caniuse.com/css-cascade-scope
  https://mozilla.github.io/standards-positions/#at-scope
  @scope (app-vbo-root) {
    @import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
    @import "scss/global/_index.scss";
    @import "scss/themes.scss";
    @import "bootstrap/scss/bootstrap";
    @import "node_modules/@angular/material/_index.scss"
  } */
}
@include not-feature-app {
  @import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
  app-vbo-root {
    @import "scss/global/_index.scss";
    @import "scss/themes.scss";
    @import "bootstrap/scss/bootstrap";
  }
  @import "node_modules/@angular/material/_index.scss";
}

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,700,900|Material+Icons");
@import "scss/body";
@import "scss/variables";
