.app-progress-bar {
  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: var(--theme-primary);
    --mdc-linear-progress-track-color: var(--grey300);
    --mdc-linear-progress-track-height: 1px;
  }
  .mat-progress-bar-buffer {
    background-color: var(--white) !important;
    border-bottom: 1px var(--grey300) solid !important;
  }
  .mat-progress-bar-fill::after {
    background-color: var(--theme-primary) !important;
  }

  .mdc-linear-progress__buffer {
    top: unset;
  }
  .mat-mdc-progress-bar.thick {
    --mdc-linear-progress-active-indicator-height: 7px;
    --mdc-linear-progress-track-height: 7px;
    --mdc-linear-progress-track-color: var(--secondary-white);
    .mdc-linear-progress__buffer {
      top: 0;
    }
  }
}
