.app-time-selection {
  .time {
    display: inline-flex;
    padding: 3px;
    color: var(--theme-primary);
    border: 1px solid var(--theme-primary);
    border-radius: 3px;
  }
  .active {
    background-color: var(--theme-primary);
    color: var(--white);
  }

  .service-window-highlighter {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: var(--greenLight);
    transform: translateY(5px);
  }
}
