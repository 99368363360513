.pulse {
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.hover {
  cursor: pointer;
}

.hover:hover {
  background-color: rgba(199, 198, 198, 0.185);
}

.no-hover {
  cursor: default !important;
}

.overflow-hidden {
  overflow: hidden;
  height: 100%;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-line {
  white-space: pre-line;
}

.logo-medium {
  height: 65px;
  max-width: 100px;
}

.logo-small {
  height: 40px;
  max-width: 70px;
}

.hidden {
  visibility: hidden;
}

.opacity-20 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"; // IE8
  filter: alpha(opacity=20);
  opacity: 0.2;
}

.opacity-40 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; // IE8
  filter: alpha(opacity=40);
  opacity: 0.4;
}
