.app-dropdown {
  .mat-form-field.mat-focused {
    .mat-form-field-label {
      color: var(--theme-primary);
    }
    .mat-form-field-ripple {
      background-color: var(--theme-primary);
    }
    .mat-form-field-required-marker {
      color: var(--theme-primary);
    }
    .mat-input-element {
      color: var(--theme-primary);
    }
  }

  .mat-form-field-flex {
    padding-top: 0px !important;
  }
  .w-formfield .mat-select-arrow-wrapper {
    padding-top: 15px !important;
  }

  .mat-select-arrow {
    color: var(--theme-primary) !important;
  }

  .mat-option {
    height: 2.5em !important;
  }
}
