.app-map {
  agm-map {
    height: calc(30vh + 100px);
  }

  .form-control {
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      font-style: italic;
      font-size: 16px;
      font-size: italic;
      text-transform: capitalize;
      font-weight: var(--font-weight-normal);
      color: var(--grey300);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding-right: 3px;
    }
  }

  .maps-cluster {
    div {
      margin-top: 16px;
    }
  }
}
