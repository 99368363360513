@import "footer-menu.scss";
@import "footer-popup.scss";

@include only-feature-app {
  app-footer-base {
    position: relative;
  }
}

@include not-feature-app {
  app-footer-base {
    position: sticky;
    bottom: 0px;
  }
}

app-footer-base {
  z-index: 1000;
}
