.app-dialog {
  .no-boxshadow {
    transition: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    -webkit-appearance: none !important;
  }
  @media screen and (max-width: 992px) {
    .mat-mdc-dialog-content.no-padding {
      padding: 0px 0px;
    }
    
    .mat-mdc-dialog-content.no-padding ~ .mat-mdc-dialog-actions {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  .mat-mdc-dialog-actions, .mat-mdc-dialog-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}
app-dialog-cookie {
  app-button {
    display: flex;
    min-height: 100%;
    .mat-mdc-tooltip-trigger {
      flex: 1;
      display: flex;
      min-height: 100%;
      .app-button {
        flex: 1;
        display: flex;
        min-height: 100%;
        button {
          flex: 1;
          align-self: stretch;
        }
      }
    }
  }
}
