@use "sass:selector";
@use "sass:list";
@use "@angular/material" as mat;
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'var(--font-family-base),"sans-serif"',
);
@include mat.typography-hierarchy($custom-typography);

@mixin with-theme() {
  @at-root {
    #{selector.unify("app-vbo-root *", &)} {
      @content (map-get($themes, 'default'));
    }
  }
  @each $brand, $theme in $themes {
    @if $brand != "default" {
      @at-root {
        #{selector.unify("app-vbo-root:has(.app.#{$brand}) *", &)} {
          @content ($theme);
        }
      }
    }
  }
}

@mixin with-theme-var($var) {
  @include with-theme() using ($theme) {
    @content (map-get($theme, $var));
  }
}
