.strikethrough {
  text-decoration: line-through;
}

.package-container {
  &.extra-mt {
    margin-top: 1.6rem !important;
  }

  .package-tag-wrapper {
    z-index: 10;
    top: -1rem;
    margin-left: 0.8rem;

    .package-tag {
      padding: 3px 10px;

      & + .package-tag {
        margin-left: 0.3rem;
      }
    }
  }
}
