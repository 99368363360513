.app-view-more {
  .viewmore-link {
    color: var(--theme-primary) !important;
    font-weight: var(--font-weight-normal) !important;
  }

  .viewmore-icon {
    color: var(--theme-primary);
    margin-top: -2px;
    margin-left: 8px;
  }

  .view-more-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;
    min-height: 44px;
  }
}
