.app-about-vehicle {
  .slider-container {
    width: calc(100% - 15px);
  }

  .bg-blue {
    background-color: #1716a3;
  }

  .plate {
    margin: -2px;
    padding-bottom: 0px !important;
    z-index: -10;
    border-radius: 4px 0px 0px 4px;
  }

  .registration-number-border {
    border: 2px solid #3e454c !important;
    border-radius: 4px;
  }
}
