app-footer {
  z-index: 1000;
  .footer-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    background-color: var(--theme-primary);
    body.hide-scroll.hide-scroll__footer-popup & {
      width: calc(100% + var(--hide-scroll__scroll-width, 0px));
      padding-right: var(--hide-scroll__scroll-width, 0px) !important;
    }
    .footer-content {
      color: var(--white);
      .card {
        background-color: transparent;
      }
    }
  }
}
