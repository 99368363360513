.custom-dialog-container .mat-dialog-container {
  padding: 24px;
  box-sizing: border-box;

  @media screen and (max-width: 450px) {
    overflow: visible;
    padding: 15px !important;
  }
}
.app {
  margin: 0;
  font-family: var(--font-family-base), sans-serif;
  font-weight: var(--font-weight-normal);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  -webkit-font-smoothing: antialiased;
}

input[type] {
  -webkit-appearance: none;
  border-radius: 0;
}
input[type="text"]::-ms-clear {
  display: none;
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

img {
  max-width: 100%;
  flex-shrink: 0;
}

.site {
  @include only-feature-app {
    position: relative;
    z-index: 0;
  }
  display: flex;
  @include not-feature-app {
    min-height: 100vh;
  }
  @include only-feature-app {
    min-height: 770px;
  }
  flex-direction: column;
  body.disable-scroll & {
    max-height: 100vh;
    overflow: hidden;
  }
}

.content {
  flex: 1;
}

.router-container {
  margin-bottom: 100px;
}

.icon-selected {
  border-bottom: 4px solid var(--theme-primary);
  border-radius: 3px;
}
.icon-pointer {
  cursor: pointer;
}

.adjust-cookies {
  max-height: 94vh;
  overflow-y: auto;
}

.map-max-height {
  height: 354px !important;
}

.app-overlay-container {
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.app-overlay-container:empty {
  display: none;
}
