.app-checkbox {
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: var(--white);
    --mdc-checkbox-selected-focus-icon-color: var(--theme-primary);
    --mdc-checkbox-selected-hover-icon-color: var(--theme-primary);
    --mdc-checkbox-selected-icon-color: var(--theme-primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--theme-primary);
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-hover-state-layer-color: var(--theme-primary);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--theme-primary);
    --mdc-checkbox-selected-focus-state-layer-color: var(--theme-primary);
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
  }

  .mat-mdc-checkbox {
    /* Disable visual focus by default */
    --mdc-checkbox-selected-focus-state-layer-opacity: 0;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  }

  &:is(:focus-visible, :has(:focus-visible)) .mat-mdc-checkbox {
    /* Reenable visual focus when visual focus selector is active */
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  }
  &:not(:is(:focus-visible, :has(:focus-visible))):focus-within
    .mat-mdc-checkbox:hover {
    /* Emulate hover when visual focus is disabled when visual focus selector is active */
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.04;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0.04;
  }

  .rounded {
    & .mdc-checkbox__background {
      border-radius: 50% !important;
    }
  }
  .square {
    & .mdc-checkbox__background {
      border-radius: 10% !important;
    }
  }

  label {
    margin-bottom: 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
  }

  @mixin checkbox-size($size) {
    --mdc-checkbox-state-layer-size: $size;
    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size) - $size) / 2);
      width: $size;
      height: $size;
      flex: 0 0 $size;
    }
    .mdc-checkbox__background {
      width: $size;
      height: $size;
      top: calc((var(--mdc-checkbox-state-layer-size) - $size) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size) - $size) / 2);
    }
  }

  mat-checkbox.xl {
    @include checkbox-size(27px);
  }

  mat-checkbox.md {
    @include checkbox-size(24px);
  }

  mat-checkbox,
  mat-checkbox.sm {
    @include checkbox-size(20px);
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox {
    opacity: 0.2 !important;
  }
  .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: inherit;
    opacity: 0.6;
  }
  .mdc-checkbox__checkmark {
    transform: scale(0.75);
  }
}
