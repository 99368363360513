@use "sass:math";

.app-radio-button {
  .mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: var(--theme-primary);
    --mdc-radio-selected-hover-icon-color: var(--theme-primary);
    --mdc-radio-selected-icon-color: var(--theme-primary);
    --mdc-radio-selected-pressed-icon-color: var(--theme-primary);
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: var(--theme-primary);
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  }

  .mdc-radio__native-control:focus + .mdc-radio__background:before {
    transform: scale(0, 0);
    opacity: 0;
    transition:
      opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
      transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  }
  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:not([disabled])
    ~ .mdc-radio__background:before {
    opacity: 0.04;
    transform: scale(1);
  }

  .mdc-radio:is(:focus-visible, :has(:focus-visible)) {
    .mdc-radio__native-control:focus + .mdc-radio__background:before {
      transform: scale(1);
      opacity: 0.12;
      transition:
        opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1),
        transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  label {
    margin-bottom: 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @mixin radio-size($size) {
    .mdc-radio {
      --mdc-radio-state-layer-size: #{$size + 8};
      padding: calc((var(--mdc-radio-state-layer-size) - $size) / 2);
      width: $size;
      height: $size;
      .mdc-radio__background {
        width: $size;
        height: $size;
        &:before {
          top: calc(-1 * (var(--mdc-radio-state-layer-size) - $size) / 2);
          left: calc(-1 * (var(--mdc-radio-state-layer-size) - $size) / 2);
        }
      }
      .mdc-radio__inner-circle {
        border-width: #{math.div($size, 2)};
      }
    }
  }

  .mat-mdc-radio-button.xl {
    @include radio-size(30px);
  }

  .mat-mdc-radio-button.md {
    @include radio-size(25px);
  }

  .mat-mdc-radio-button,
  .mat-mdc-radio-button.sm {
    @include radio-size(20px);
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-disabled .mdc-checkbox {
    opacity: 0.2 !important;
  }
  .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: inherit;
    opacity: 0.6;
  }
}
