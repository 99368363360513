%app-footer-popup-common {
  position: absolute;
  display: none;
  overflow: hidden;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;

  body.hide-scroll.hide-scroll__footer-popup & {
    right: calc(0px - var(--hide-scroll__scroll-width, 0px));
  }
  &.footer-popup__open {
    display: block;
  }
  .footer-popup-content {
    width: 100%;
  }
  .footer-popup-container {
    pointer-events: auto;
    position: relative;
    height: 100%;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@include only-feature-app {
  app-footer-popup {
    @extend %app-footer-popup-common;
    z-index: 1001;
    top: 0;
    bottom: 0;
  }
  // START: RWS WORKAROUND
  .row > app-footer-popup {
    width: initial;
    max-width: initial;
    padding-right: initial;
    padding-left: initial;
  }
  // === END: RWS WORKAROUND
}
@include not-feature-app {
  app-footer-popup {
    @extend %app-footer-popup-common;
    height: calc(100vh - 90px - 100%);
    bottom: 100%;

    @media screen and (max-width: 1200px) {
      height: calc(100vh - 60px - 100%);
    }
    &.footer-popup__scroll {
      height: calc(100vh - 100%) !important;
    }
  }
}
