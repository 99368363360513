app-footer-menu {
  $cmp: &;
  position: absolute;
  bottom: calc(100% + 1rem);
  right: 1rem;
  // Set min-width and height for better animation
  min-width: 60px;
  min-height: 60px;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 0;
  transition: border-radius 150ms ease-in-out;
  width: 60px !important;

  &:hover,
  &:focus,
  &:focus-within {
    width: 155px !important;
    border-radius: 4px;
    .footer-menu__icon {
      display: block;
      width: 0;
      height: 0;
      opacity: 0;
    }
    .footer-menu__nav {
      pointer-events: auto;
      a {
        width: auto;
        height: auto;
        padding: 0.8rem;
        opacity: 1;
      }
    }
  }

  .footer-menu__icon {
    width: auto;
    height: auto;
    opacity: 1;
    overflow: hidden;
    transition: all 150ms ease-in-out;
  }

  .footer-menu__nav {
    pointer-events: none;
    a {
      display: block;
      padding: 0;
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition-property: height, padding, opacity;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
    }
  }
}
