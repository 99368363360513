@include not-feature-app() {
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
  body.hide-scroll {
    overflow-y: hidden;
    overflow-x: visible;
    width: calc(100vw - var(--hide-scroll__scroll-width, 0px));
    height: calc(100vh + var(--hide-scroll__scroll-pos, 0px));
    margin-top: calc(0px - var(--hide-scroll__scroll-pos, 0px));
    margin-right: var(--hide-scroll__scroll-width, 0px);
  }
}
