.app-text-box {
  .search-clear {
    z-index: 100;
    margin-left: -40px;
    position: relative;
  }

  .input-border-bottom {
    border-bottom-color: var(--theme-primary) !important;
    border-bottom-width: 4px !important;
  }
  .input-border-bottom-success {
    border-bottom-color: var(--green100) !important;
    border-bottom-width: 4px !important;
  }

  .input-border-bottom-error {
    border-bottom-color: var(--red100) !important;
    border-bottom-width: 4px !important;
  }

  .smaller-height {
    height: 32px !important;
    text-align: right;
    max-width: 110px;
    padding-left: 3px;
    border-bottom: none !important;
  }
  .form-control {
    z-index: 1 !important;
    font-weight: var(--font-weight-light);
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    height: 48px;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      font-style: italic;
      font-size: 16px;
      font-size: italic;
      text-transform: capitalize;
      font-weight: var(--font-weight-normal);
      color: var(--grey300);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding-right: 3px;
    }
  }
}
